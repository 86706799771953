@media print {
	#sidebar, #navbar {
		display: none;
	}
	.formitem {
		margin-bottom: 8px !important;
	}
	.formitem__label {
		font-size: 12px !important;
	}
	.formitem__input {
		min-height: auto;
		font-size: 13px !important;
	}
	.noprint {
		display: none;
	}
	@page {
		margin: 1cm 0 !important;
		size: A4 portrait;
	}
}